import * as React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

export default function ErrorMessage({message}) {
    return (
        <Stack sx={{ width: '340px', marginTop: 0, marginBottom: 5}} spacing={2}>
            <Alert severity="error">
                {message}
            </Alert>
        </Stack>
    );
}
