import React, { useEffect } from "react";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

export default function SuccessMessage({message}) {
    const [showElement, setShowElement] = React.useState(true);
    useEffect(() => {
        setTimeout(function () {
            setShowElement(false);
        }, 5000);
    }, []);
    return (
        <div>
            {showElement &&
                <Stack sx={{ width: '100%' }} style={{opacity: showElement ? 1 : 0}} spacing={2}>
                    <Alert severity="success">{message}</Alert>
                </Stack>
            }
        </div>
    );
}
